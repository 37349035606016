import React from "react";
import { useOverrides } from "@quarkly/components";
import { Text, Em, Box, Link, List, Section } from "@quarkly/widgets";
const defaultProps = {
	"padding": "2rem 0 2rem 0",
	"background": "--color-dark",
	"quarkly-title": "Footer-13",
	"sm-padding": "50px 0 50px 0"
};
const overrides = {
	"text": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 2rem 0px",
			"color": "--light",
			"text-align": "center",
			"font": "--headline4",
			"text-transform": "uppercase",
			"children": "Clip & Clove Barbershop"
		}
	},
	"box": {
		"kind": "Box",
		"props": {
			"min-width": "100px",
			"display": "grid",
			"grid-gap": "16px 24px",
			"margin": "0px 0px 0 0px",
			"md-grid-template-columns": "1fr",
			"md-grid-gap": "36px 0",
			"md-margin": "0px 0px 50px 0px",
			"grid-template-columns": "repeat(2, 1fr)"
		}
	},
	"box1": {
		"kind": "Box",
		"props": {
			"min-width": "100px",
			"height": "auto"
		}
	},
	"text1": {
		"kind": "Text",
		"props": {
			"color": "#d7c7c5",
			"text-align": "center",
			"border-color": "#b8acac",
			"md-margin": "0px 0px 15px 0px",
			"margin": "0px 0px 0 0px",
			"font": "--headline5",
			"children": "Kapushanska St, 87, Uzhhorod, Zakarpattia Oblast, 88000"
		}
	},
	"box2": {
		"kind": "Box",
		"props": {
			"min-width": "100px",
			"display": "flex",
			"flex-direction": "column",
			"align-items": "center",
			"height": "auto"
		}
	},
	"link": {
		"kind": "Link",
		"props": {
			"color": "#d7c7c5",
			"text-decoration-line": "initial",
			"display": "flex",
			"text-align": "center",
			"margin": "0px 0px 15px 0px",
			"hover-color": "#f5eceb",
			"font": "--headline5",
			"href": "tel:0322960335",
			"children": "0967201181"
		}
	},
	"box3": {
		"kind": "Box",
		"props": {
			"min-width": "100px",
			"height": "auto",
			"display": "none"
		}
	},
	"text2": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 15px 0px",
			"color": "#d7c7c5",
			"text-align": "center",
			"border-color": "#b8acac",
			"font": "--base",
			"display": "none",
			"children": <>
				Понеділок - Субота (10:00 - 20:00){"\n"}
				<br />
				Нд (11:00 - 19:00)
			</>
		}
	},
	"list": {
		"kind": "List",
		"props": {
			"margin": "0px 0px 0px 0px",
			"padding": "12px 0px 12px 0px",
			"list-style-type": "none",
			"as": "ul",
			"display": "flex",
			"align-items": "center",
			"justify-content": "center"
		}
	},
	"link1": {
		"kind": "Link",
		"props": {
			"href": "/index",
			"color": "white",
			"padding": "6px 12px 6px 12px",
			"text-decoration-line": "initial",
			"display": "flex",
			"font": "20px/30px sans-serif",
			"hover-color": "--lightD2",
			"transition": "background-color 0.1s ease 0s",
			"children": "ГОЛОВНА"
		}
	},
	"link2": {
		"kind": "Link",
		"props": {
			"color": "white",
			"padding": "6px 12px 6px 12px",
			"text-decoration-line": "initial",
			"display": "flex",
			"font": "20px/30px sans-serif",
			"hover-color": "--lightD2",
			"transition": "background-color 0.1s ease 0s",
			"children": "КОНТАКТИ",
			"href": "/contacts"
		}
	},
	"link3": {
		"kind": "Link",
		"props": {
			"href": "/menu",
			"color": "white",
			"padding": "6px 12px 6px 12px",
			"text-decoration-line": "initial",
			"display": "flex",
			"font": "20px/30px sans-serif",
			"hover-color": "--lightD2",
			"transition": "background-color 0.1s ease 0s",
			"children": "ПОСЛУГИ"
		}
	}
};

const Header23 = props => {
	const {
		override,
		children,
		rest
	} = useOverrides(props, overrides, defaultProps);
	return <Section {...rest}>
		<Text {...override("text")} />
		<Box {...override("box")}>
			<Box {...override("box1")}>
				<Text {...override("text1")} />
			</Box>
			<Box {...override("box2")}>
				<Link {...override("link")} />
			</Box>
			<Box {...override("box3")}>
				<Text {...override("text2")} />
			</Box>
		</Box>
		<List {...override("list")}>
			<Link {...override("link1")} />
			<Link {...override("link2")} />
			<Link {...override("link3")} />
		</List>
		{children}
	</Section>;
};

Object.assign(Header23, { ...Section,
	defaultProps,
	overrides
});
export default Header23;